<template>
	<div id="app" class="wrapper-content">
		<router-view />
		<!-- Router路由显示组件，类似iframe -->
		<template>
			<el-backtop target=".wrapper-content"></el-backtop>
		</template>
	</div>
</template>
<script>
	export default {
		data() {
			return {

			};
		},
		mounted() {},
		beforeDestroy() {},
		methods: {}
	};
</script>
<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		/* text-align: center; */
		color: #2c3e50;
	}

	nav {
		padding: 30px;
	}

	nav a {
		font-weight: bold;
		color: #2c3e50;
	}

	nav a.router-link-exact-active {
		color: #42b983;
	}

	* {
		padding: 0px;
		margin: 0px;
		/* font-family: "PingFang"; */
	}

	/* @font-face {
		font-family: 'PingFang';
		src: url('../font/PINGFANG-SC.TTF') format('opentype');
	} */

	body {}

	p {
		margin-bottom: 0px;
	}

	a {
		text-decoration: none !important;
	}

	.container-fluid {
		padding: 0px;
		margin: 0px;
		/* position: relative; */
		/* padding-bottom: 80px; */
		display: inline;
		margin-bottom: 30px;
	}

	.img_fluid {
		width: 100%;
		height: auto;
		vertical-align: bottom;
	}

	.base-title {
		font-size: 16px;
	}

	.base-text {
		font-size: 12px;
	}

	.el-menu--horizontal>.el-menu-item {
		border-bottom: 0px !important;
	}

	.flex-box .el-menu {
		border: 0px;
	}

	.flex-box {
		margin-top: 25px;
	}

	.homelogo {
		width: 14px;
		height: auto;
	}

	.el-breadcrumb {
		margin: 25px 0 0 0;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.bread_text {
		color: #171717 !important;
		font-weight: 600 !important;
	}

	.userMenu .el-menu .el-menu-item {
		/* word-break:break-all; */
		white-space: unset;
		line-height: unset !important;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}

	.userMenu .el-menu .el-menu-item span {
		text-align: left;
	}

	.el-popover {
		min-width: 50px !important;
	}
</style>
