<template>
	<footer class="foor">
		<div class="foot-box">
			<div class="foot-box-top">
				<div class="foot-logo">
					<img src="../../static/images/logo3.png">
				</div>
				<div class="foot-menu">
					<p class="foot-menu-title">菜单导航</p>
					<div class="foot-menu-box">
						<a href="http://www.otopchina.com" class="foot-menu-item">首页</a>
						<router-link to="/aboutotop" class="foot-menu-item">平台介绍</router-link>
						<router-link to="/equity" class="foot-menu-item">企业权益</router-link>
						<router-link to="/newCenter" class="foot-menu-item">新闻中心</router-link>
						<router-link to="/contactUs" class="foot-menu-item">联系我们</router-link>
						<router-link :to="{name:'QA', params: {helpClassID:134}}" class="foot-menu-item">常见问题
						</router-link>
						<a href='http://shop.otopchina.com/' target="_blank" class="foot-menu-item">电商平台</a>
					</div>
				</div>
				<div class="foot-menu">
					<p class="foot-menu-title">平台矩阵</p>
					<div class="foot-menu-box">
						<a href="http://www.ccpnt.org/" target="_blank" class="foot-menu-item">中国民贸</a>
						<a href="http://www.otopchina.org/index.html" target="_blank" class="foot-menu-item">中心官网</a>
						<a href="https://edu.otopchina.com/" target="_blank" class="foot-menu-item">学习平台</a>
						<a href="http://standard.otopchina.com/" target="_blank" class="foot-menu-item">标准平台</a>
						<a href="http://finance.otopchina.com/index.html" target="_blank"
							class="foot-menu-item">金融平台</a>


						<a href="http://shop.otopchina.com/" target="_blank" class="foot-menu-item">电商平台</a>
						<a href="http://expo.otopchina.com/" target="_blank" class="foot-menu-item">一乡一品博览会</a>
						<a href="http://cysj.otopchina.com/index.html" target="_blank" class="foot-menu-item">设计大赛</a>
						<a href="http://api.show.otopchina.com/otop/" target="_blank" class="foot-menu-item">一乡一品数据大屏</a>
						
					</div>
				</div>
				<div class="foot-info">
					<div class="foot-info-box">
						<p class="foot-menu-title">联系我们</p>
						<p class="foot-menu-text">联系地址：湖南省长沙市长沙县香樟东路230号会展星街6号栋</p>
						<p class="foot-menu-text">联系电话：0731-82988108（工作日 9:00-18:00）</p>
					</div>
					<div class="foot-info-box">
						<p class="foot-menu-title">关注我们</p>
						<div class="foot-info-box-list">
							<div class="foot-info-box-item">
								<div style="width: 70px;height: 70px;margin: 0px auto 10px auto;background: #D9D9D9;">
									<img src="../../static/images/ccpntlogocode.png" style="width: 70px;height: 70px;">
								</div>
								<p class="foot-info-box-item-title">中国民族贸易促进会</p>
							</div>
							<div class="foot-info-box-item">
								<div style="width: 70px;height: 70px;margin: 0px auto 10px auto;background: #D9D9D9;">
									<img src="../../static/images/otoplogocode.png" style="width: 70px;height: 70px;">
								</div>
								<p class="foot-info-box-item-title">中国一乡一品</p>
							</div>
							<div class="foot-info-box-item">
								<div style="width: 70px;height: 70px;margin: 0px auto 10px auto;background: #D9D9D9;">
									<img src="../../static/images/erweima.jpg" style="width: 70px;height: 70px;">
								</div>
								<p class="foot-info-box-item-title">一乡一品服务平台</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="divider"></div>

			<div class="foot-bottom">
				<div class="foot-bottom-left">
					<div class="foot-bottom-text-box">
						<p class="foot-info-box-item-title">主管单位：中国民族贸易促进会</p>
						<p class="foot-info-box-item-title">主办单位：中国民贸一乡一品产业促进中心</p>
					</div>
					<div class="foot-bottom-text-box">
						<p class="foot-info-box-item-title">运营管理：湖南一乡一品数智科技有限公司</p>
						<p class="foot-info-box-item-title"></p>
						<!-- 技术支持：一乡一品科技产业有限公司信息技术部 -->
						<p class="foot-info-box-item-title">技术支持电话：0731-82988108（工作日 9:00-18:00）</p>
					</div>
					<div class="foot-bottom-text-box">
						<span class="foot-info-box-item-title">Copyright © 2019-2025 otopchina.com All Rights Reserved.
							中国民贸一乡一品产业促进中心 版权所有</span>
					</div>
					<div class="foot-bottom-text-box">
						<a href="https://ythzxfw.miit.gov.cn/index" target="_blank" class="foot-info-box-item-title"> 增值电信业务经营许可证：
							湘B2-20200169</a>
						<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
							class="foot-info-box-item-title">湘ICP备19014526号</a>

						<a target="_blank" class="foot-info-box-item-title" style="display: flex;"
							href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43010402001196">
							<img src="../../static/images/beian.png" style="width: 18px;height: auto;align-items: center;" />
							<p> 湘公网安备 43010402001196号</p>
						</a>
					</div>



					<div class="foot-bottom-text-box">
						<p class="foot-info-box-item-title"></p>
					</div>
				</div>
				<div class="foot-bottom-right">
					<a href="https://www.creditchina.gov.cn/" target="_blank"><img
							src="../../static/images/footimg1.png"></a>
					<a href="https://www.gsxt.gov.cn/" target="_blank"><img src="../../static/images/footimg2.png"></a>
					<a href="http://www.kxnet.cn/" target="_blank"><img src="../../static/images/footimg3.png"></a>
					<a href="https://www.12377.cn/" target="_blank"><img src="../../static/images/footimg4.png"></a>
				</div>
			</div>

		</div>


		<div class="rightNav">
			<el-popover placement="left" width="100" trigger="hover">
				<!-- 小程序筹备上线中，敬请期待！ -->
				<img src="../../static/images/miniappcode.jpg" class="rigtnNavItemPhone">
				<span class="rigthNavBtn" slot="reference">
					<img src="../../static/images/miniapp.svg" class="rigtnNavLogo">
					小程序
				</span>
			</el-popover>

			<el-popover placement="left" width="100" trigger="hover">
				<img src="../../static/images/otoplogocode.png" class="rigtnNavItemPhone">
				<span class="rigthNavBtn" slot="reference">
					<img src="../../static/images/erweima.svg" class="rigtnNavLogo">微信</span>
			</el-popover>

			<router-link class="rigthNavBtn" to="/contactUs" slot="reference">
				<img src="../../static/images/lianxisvg.svg" class="rigtnNavLogo">
				联系我们
			</router-link>
		</div>


	</footer>
</template>

<script>
	export default {
		name: 'baseFoot',
		data() {
			return {
				activeIndex: '1',
				drawer: false,
			};
		},
		methods: {
			handleSelect(key, keyPath) {
				console.log(key, keyPath);
			},
		},
		created() {
			window.addEventListener("scroll", this.showIcon);
		}
	}
</script>
<script>
	var _hmt = _hmt || [];
	(function() {
		var hm = document.createElement("script");
		hm.src = "https://hm.baidu.com/hm.js?274fa8d88770f574f120eec64003fc09";
		var s = document.getElementsByTagName("script")[0];
		s.parentNode.insertBefore(hm, s);
	})();
</script>


<style>

	
	@media screen and (min-width: 769px) {
		.show_m {
			display: none !important;
		}
		
		.foor {
			width: 100%;
			background: #303030;
			padding: 45px 0 10px 0;
			margin-top: 45px;
			min-width: 1237px;
		}
		
		.foot-box {
			max-width: 1237px;
			min-width: 1237px;
			margin: 0px auto;
		}
		
		.foot-box-top {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		
		.foot-logo {
			width: 277px;
		}
		
		.foot-logo img {
			width: 100%;
			height: auto;
		}
		
		.foot-menu {
			width: 15%;
			/* margin-left: 50px; */
		}
		
		.foot-menu-title {
			font-weight: 900;
			font-size: 18px;
			line-height: 32px;
			letter-spacing: 1px;
			color: #FFFFFF;
			text-align: left;
			margin-bottom: 15px;
		}
		
		.foot-menu-box {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			flex-wrap: wrap;
		}
		
		.foot-menu-item:nth-child(2n) {
			margin-right: 0px !important;
		}
		
		.foot-menu-item {
			width: 35%;
			padding: 0 0%;
			margin: 0px 25% 15px 0;
			font-size: 14px;
			line-height: 18px;
			color: rgba(255, 255, 255, 0.5);
			text-align-last: justify;
			text-align: justify;
			text-justify: distribute-all-lines;
		}
		
		.foot-info {
			/* width: 30%; */
		}
		
		.foot-menu-text {
			font-size: 14px;
			line-height: 18px;
			color: rgba(255, 255, 255, 0.5);
			margin-bottom: 10px;
			text-align: left;
		}
		
		.foot-info-box-list {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
		}
		
		.foot-info-box-item {
			/* width: 30%; */
		}
		
		.foot-info-box-item-title {
			font-size: 14px;
			line-height: 18px;
			color: rgba(255, 255, 255, 0.5);
			margin-right: 10px;
		}
		
		.divider {
			width: 100%;
			height: 1px;
			margin: 30px 0;
			background: rgba(255, 255, 255, 0.5) !important;
			color: #E9E9E9 !important;
		}
		
		.foot-bottom {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		
		.foot-bottom-left {
			width: 65%;
		}
		
		.foot-bottom-right {
			width: 30%;
		}
		
		.foot-bottom-text-box {
			display: flex;
			flex-direction: row;
			/* justify-content: space-between; */
			margin-bottom: 10px;
		}
		
		.foot-bottom-right {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			flex-wrap: wrap;
		}
		
		.foot-bottom-right img {
			width: auto;
			height: 40px;
			margin: 0px 0px 10px 20px;
		}
		
		.el-backtop {
			position: fixed;
			background-color: #fff;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			color: #409eff;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			box-shadow: 0 0 6px rgb(0 0 0 / 12%);
			cursor: pointer;
			z-index: 5;
			right: 10px !important;
			bottom: 140px !important;
		}
		
		.rightNav {
			position: fixed;
			background-color: #fff;
			box-shadow: 0 0 6px rgb(0 0 0 / 12%);
			z-index: 5;
			cursor: pointer;
			right: 0px;
			bottom: 200px;
			display: flex;
			flex-direction: column;
			padding: 5px;
		
		}
		
		.rigthNavBtn {
			color: #333;
			font-size: 12px;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin: 5px 0;
		}
		
		.rigtnNavItemPhone {
			width: 100px;
			height: 100px;
		}
		
		.rigtnNavLogo {
			font-size: 24px;
			width: 24px;
			height: 24px;
			margin: 10px 0px 5px 0px;
		}
	}
	
	@media screen and (max-width: 769px) {
		.show_pc {
			display: none !important;
		}
		
		.foor {
			width: 100%;
			background: #303030;
			padding: 45px 0 10px 0;
			margin-top: 45px;
		}
		
		.foot-logo {
			width: 200px;
		}
		
		.foot-logo img {
			width: 100%;
			height: auto;
		}
		
		.foot-box {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			margin: 0px auto;
		}
		
		.foot-menu {
			/* width: 15%; */
			/* margin-left: 50px; */
			max-width: 381px;
			margin: 30px auto 0px auto;
			padding: 0px 20px;
		}
		
		.foot-menu-title {
			font-weight: 900;
			font-size: 18px;
			line-height: 32px;
			letter-spacing: 1px;
			color: #FFFFFF;
			text-align: left;
			/* margin-bottom: 15px; */
		}
		
		.foot-menu-box {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			flex-wrap: wrap;
		}
		
		/* .foot-menu-item:nth-child(2n) {
			margin-right: 0px !important;
		} */
		
		.foot-menu-item {
			padding: 0 0%;
			margin: 15px 15px 0px 0;
			font-size: 14px;
			line-height: 18px;
			color: rgba(255, 255, 255, 0.5);
			text-align-last: justify;
			text-align: justify;
			text-justify: distribute-all-lines;
		}
		
		.foot-info {
			
		}
		
		.foot-menu-text {
			font-size: 14px;
			line-height: 18px;
			color: rgba(255, 255, 255, 0.5);
			margin-top: 15px;
			text-align: left;
		}
		.foot-box-top{
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}
		.foot-info-box{
			margin-top: 30px;
			padding: 0px 20px;
		}
		
		.foot-info-box-list {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-top: 15px;
		}
		
		.foot-info-box-item {
			/* width: 30%; */
		}
		
		.foot-info-box-item-title {
			font-size: 14px;
			line-height: 18px;
			color: rgba(255, 255, 255, 0.5);
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}
		
		.divider {
			width: 100%;
			height: 1px;
			margin: 30px 0;
			background: rgba(255, 255, 255, 0.5) !important;
			color: #E9E9E9 !important;
		}
		
		.foot-bottom {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}
		
		.foot-bottom-left {
			/* width: 65%; */
		}
		
		.foot-bottom-right {
			width: 100%;
		}
		
		.foot-bottom-text-box {
			display: flex;
			flex-direction: column;
			/* justify-content: space-between; */
			margin-bottom: 10px;
			padding: 0px 20px;
		}
		
		.foot-bottom-right img {
			width: auto;
			height: 40px;
			margin: 0px 10px 20px 10px;
		}
		
		.el-backtop {
			position: fixed;
			background-color: #fff;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			color: #409eff;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			box-shadow: 0 0 6px rgb(0 0 0 / 12%);
			cursor: pointer;
			z-index: 5;
			right: 10px !important;
			bottom: 140px !important;
		}
		
		.rightNav {
			position: fixed;
			background-color: #fff;
			box-shadow: 0 0 6px rgb(0 0 0 / 12%);
			z-index: 5;
			cursor: pointer;
			right: 0px;
			bottom: 200px;
			display: flex;
			flex-direction: column;
			padding: 5px;
		
		}
		
		.rigthNavBtn {
			color: #333;
			font-size: 12px;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin: 5px 0;
		}
		
		.rigtnNavItemPhone {
			width: 100px;
			height: 100px;
		}
		
		.rigtnNavLogo {
			font-size: 24px;
			width: 24px;
			height: 24px;
			margin: 10px 0px 5px 0px;
		}
	}
</style>
