<template>

	<div class="container-fluid" id="app">
		<!-- head -->
		<base-Head headActiveIndex="HomeView" @childFn="parentFn"></base-Head>
		<!-- head -->

		<!-- banner -->
		<div class="index_banner">
			<!--原banner: https://cdmfimg.otopchina.com/20220901_3eac591b8131eae6d1f63ed6d3120155 -->
			<!-- 博览会Banner:https://cdmfimg.otopchina.com/20221214_e700fece36d6f9003b2bd7fca53757d4 -->
			<img src="https://cdmfimg.otopchina.com/20230110_24edc273be6b5635c51287f13c60b9b8"
				class="img_fluid show_pc">
			<img src="https://cdmfimg.otopchina.com/20240805_eec35f64e398333ad2cf1e14dba73fbe" class="img_fluid show_m">
			<div class="index_banner_search">
				<p class="index_banner_search_title">
					欢迎加入中国一乡一品产业促进计划！
				</p>

				<div class="index_banner_search_type">
					<div class="index_banner_search_type_item" :class="searchType==1?'search_type_item_action':''"
						@click="searchShow(1)">
						查企业
						<img src="https://cdmfimg.otopchina.com/20230505_fdcdd35e02f9721e9b713bf84cf5f5df" alt=""
							class="search_type_img">
					</div>
					<div class="index_banner_search_type_item" :class="searchType==2?'search_type_item_action':''"
						@click="searchShow(2)">
						查产品
						<img src="https://cdmfimg.otopchina.com/20230505_fdcdd35e02f9721e9b713bf84cf5f5df" alt=""
							class="search_type_img">
					</div>
					<div class="index_banner_search_type_item" :class="searchType==3?'search_type_item_action':''"
						@click="searchShow(3)">
						查生态伙伴
						<img src="https://cdmfimg.otopchina.com/20230505_fdcdd35e02f9721e9b713bf84cf5f5df" alt=""
							class="search_type_img">
					</div>
				</div>
				<div class="search_box" @keydown.enter="goSearch">
					<el-autocomplete class="inline-input input-with-select search-input-box" v-model="keyword"
						:fetch-suggestions="querySearch" placeholder="请输入需要查询的企业或产品名称或生态伙伴" @select="handleSelect">
						<el-button slot="append" @click="goSearch">
							<a href="javascript:;">立即查询</a>
						</el-button>
					</el-autocomplete>

					<!-- <el-input placeholder="查询企业或产品名称" v-model="keyword" class="input-with-select">
						<el-button slot="append">
							<router-link to="/search">立即查询</router-link>
						</el-button>
					</el-input> -->
				</div>
				<div class="hot_search">
					<div class="hot_search_logo">
						<img src="../../static/images/hot.svg">
						热门<br>搜索
					</div>
					<div class="hot_search_list">
						<div class="hot_search_item" @click="setHotSearch(item.relateID,item.type)"
							v-for="item in initSearch.hot_list">
							{{item.keyword}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- banner -->

		<div class="navlist">
			<!-- <a class="navitem" v-for="item in index_data.data.index_banner">
				<div class="navitem-left">
					<p class="navitem-left-title">{{item.title}} ></p>
					<p class="navitem-left-text">{{item.title}}</p>
				</div>
				<img :src="item.thumb" alt="" class="navitem-img">
			</a> -->
			<router-link to="/myProduct" class="navitem">
				<div class="navitem-left">
					<p class="navitem-left-title">申请数字身份证 ></p>
					<p class="navitem-left-text">产品申报系统</p>
				</div>
				<img src="../../static/images/pingding.png" alt="" class="navitem-img">
			</router-link>
			<router-link to="/authentication" class="navitem">
				<div class="navitem-left">
					<p class="navitem-left-title">生态伙伴认证 ></p>
					<p class="navitem-left-text">生态伙伴认证申报系统</p>
				</div>
				<img src="../../static/images/peixun.png" alt="" class="navitem-img">
			</router-link>


			<router-link to="/companyList" class="navitem">
				<div class="navitem-left">
					<p class="navitem-left-title">数字身份证企业列表 ></p>
					<p class="navitem-left-text">数字身份证企业查询系统</p>
				</div>
				<img src="../../static/images/chaxun.png" alt="" class="navitem-img">
			</router-link>

			<router-link to="/productList" class="navitem">
				<div class="navitem-left">
					<p class="navitem-left-title">产品列表 ></p>
					<p class="navitem-left-text">认证产品查询系统</p>
				</div>
				<img src="../../static/images/suyuan.png" alt="" class="navitem-img">
			</router-link>


			<!-- href="http://expo.otopchina.com/" target="_blank"-->
			<router-link to="/serviceProviders" class="navitem">
				<div class="navitem-left">
					<p class="navitem-left-title">认证生态伙伴列表 ></p>
					<p class="navitem-left-text">生态伙伴查询系统</p>
				</div>
				<img src="../../static/images/bolanhui.png" alt="" class="navitem-img">
			</router-link>
		</div>

		<div class="newList">
			<router-link class="newList-left" :to="'/newDetail?newID='+index_data.data.index_news_banner.newID">
				<div class="newList-left-img">
					<img :src="index_data.data.index_news_banner.cover" alt="" class="img_fluid"
						onerror="this.src='http://cdmfimg.otopchina.com/20220727_de5962c15bbc8a24b93e3b70c6e33fe8'">
				</div>
				<div class="newList-left-info">
					<div class="newList-left-title">{{index_data.data.index_news_banner.title}}</div>
					<div class="newList-left-text">{{index_data.data.index_news_banner.author}}
						{{index_data.data.index_news_banner.time}}
					</div>
				</div>
			</router-link>
			<div class="newList-rigth">
				<el-tabs v-model="activeName" @tab-click="handleClick" stretch>
					<el-tab-pane label="最新动态" name="1">
						<div class="newList-box">
							<router-link class="newItem" v-for="item in newList" :to="'/newDetail?newID='+item.newID">
								<div class="newItem-title">{{item.title}}</div>
								<div class="newItem-text">{{item.addTime}}</div>
								<!-- {{item.author}}  -->
							</router-link>
							<div class="newMore">
								<router-link to="/newCenter">查看全部 ></router-link>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="通知公告" name="5">
						<div class="newList-box">
							<router-link class="newItem" v-for="item in newList" :to="'/newDetail?newID='+item.newID">
								<div class="newItem-title">{{item.title}}</div>
								<div class="newItem-text">{{item.addTime}}</div>
								<!-- {{item.author}}  -->
							</router-link>
							<div class="newMore">
								<router-link to="/newCenter">查看全部 ></router-link>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="政策法规" name="2">
						<div class="newList-box">
							<router-link class="newItem" v-for="item in newList" :to="'/newDetail?newID='+item.newID">
								<div class="newItem-title">{{item.title}}</div>
								<div class="newItem-text">{{item.addTime}}</div>
								<!-- {{item.author}}  -->
							</router-link>
							<div class="newMore">
								<router-link to="/newCenter">查看全部 ></router-link>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="行业资讯" name="3">
						<div class="newList-box">
							<router-link class="newItem" v-for="item in newList" :to="'/newDetail?newID='+item.newID">
								<div class="newItem-title">{{item.title}}</div>
								<div class="newItem-text">{{item.addTime}}</div>
								<!-- {{item.author}}  -->
							</router-link>
							<div class="newMore">
								<router-link to="/newCenter">查看全部 ></router-link>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="媒体报道" name="4">
						<div class="newList-box">
							<router-link class="newItem" v-for="item in newList" :to="'/newDetail?newID='+item.newID">
								<div class="newItem-title">{{item.title}}</div>
								<div class="newItem-text">{{item.addTime}}</div>
								<!-- {{item.author}}  -->
							</router-link>
							<div class="newMore">
								<router-link to="/newCenter">查看全部 ></router-link>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>

		<!-- <div class="tixi">
			<p class="tixi_title">
				中国一乡一品产业促进计划十四项“新质生产力”综合性产业服务体系
			</p>
			<p class="tixi_text">
				<span style="font-weight: bold;color: black;">科学谋划，构建特色产业体系。</span>
				服务体系包括：教育培训服务体系、标准赋能服务体系、产业规划服务体系、公用品牌服务体系、产品评价认证服务体系...
			</p>

			<div class="tixi_list">
				<div class="tixi_item" v-for="item in tixiList">
					<p class="tixi_item_title">
						{{item.title}}
					</p>
					<div class="tixi_item_line" style="width:60px;height: 2px;background: #F95538;margin-top: 25px;">
					</div>
					<div class="tixi_hover_item">
						<div class="tixi_hover_item_title">{{item.title}}</div>
						<div class="tixi_hover_item_text">{{item.stext|stextFn}}</div> -->
		<!-- <div class="tixi_hover_item_xq">了解详情 ></div> -->
		<!-- </div>
				</div>
			</div>
			<router-link to="/aboutserve"
				style="text-align: center;color: #FF5234;font-size: 14px;display: block;margin-top: 20px;">查看全部服务体系
				></router-link>
		</div> -->


		<div class="lstx">
			<div class="lstx_title">
				中国一乡一品产业促进计划 <br />
				“6+3+N”产业赋能服务体系
			</div>

			<div class="liutixi">
				<div class="liutixi_img">
					<img src="../../static/images/lstx/6.png" alt="" />
				</div>

				<div class="liutixi_box">
					<div class="liutixi_box_title">
						<img src="../../static/images/lstx/lstx_l_title.png" alt="" />
					</div>
					<div class="liutixi_box_list">

						<div class="liutixi_box_item" v-for="item in serviceTypeList.list1">

							<div class="liutixi_box_item_box">
								<img :src="item.logo" alt="" />
								<p class="liutixi_box_item_title">{{item.name}}</p>
							</div>

							<div class="liutixi_box_item_textBox">
								<div class="liutixi_box_item_textBox_box">
									<div class="liutixi_box_item_textBox_title">
										{{item.name}}
									</div>
									<div class="liutixi_box_item_textBox_text">
										{{item.service_desc}}
									</div>

									<img src="../../static/images/lstx/icon_xjt.png" alt=""
										class="liutixi_box_item_textBox_img" />
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>



			<div class="liutixi">
				<div class="liutixi_img">
					<img src="../../static/images/lstx/3.png" alt="" />
				</div>

				<div class="liutixi_box">
					<div class="liutixi_box_title">
						<img src="../../static/images/lstx/lstx_s_title.png" alt="" />
					</div>
					<div class="liutixi_box_list">

						<div class="liutixi_box_item" style="width: 33.333333%;" v-for="item in serviceTypeList.list2">

							<div class="liutixi_box_item_box">
								<img :src="item.logo" alt="" />
								<p class="liutixi_box_item_title">{{item.name}}</p>
							</div>

							<div class="liutixi_box_item_textBox">
								<div class="liutixi_box_item_textBox_box">
									<div class="liutixi_box_item_textBox_title">
										{{item.name}}
									</div>
									<div class="liutixi_box_item_textBox_text">
										{{item.service_desc}}
									</div>

									<img src="../../static/images/lstx/icon_xjt.png" alt=""
										class="liutixi_box_item_textBox_img" />
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>



			<div class="liutixi">
				<div class="liutixi_img">
					<img src="../../static/images/lstx/N.png" alt="" />
				</div>

				<div class="liutixi_box">
					<div class="liutixi_box_title">
						<img src="../../static/images/lstx/lstx_n_title.png" alt="" />
					</div>
					<div class="ntixi_box_content">
						不断吸纳认同中国一乡一品理念的企业及机构加入，因地制宜推动乡村振兴战略，助力培育壮大新质生产力，整合涵盖文创设计、包装包材、科技研发、产品孵化、公用品牌、电商服务、检验检测、会议会展、招商引资、数字赋能、园区投运、生产制造等N个三产融合发展的产业要素。
					</div>
					<div class="liutixi_box_list" style="justify-content: flex-start;">

						<div class="liutixi_box_item" v-for="item in serviceTypeList.list3">

							<div class="liutixi_box_item_box">
								<img :src="item.logo" alt="" />
								<p class="liutixi_box_item_title">{{item.name}}</p>
							</div>

							<div class="liutixi_box_item_textBox">
								<div class="liutixi_box_item_textBox_box">
									<div class="liutixi_box_item_textBox_title">
										{{item.name}}
									</div>
									<div class="liutixi_box_item_textBox_text">
										{{item.service_desc}}
									</div>

									<img src="../../static/images/lstx/icon_xjt.png" alt=""
										class="liutixi_box_item_textBox_img" />
								</div>
							</div>
						</div>



						<div class="liutixi_box_item">
							<div class="liutixi_box_item_box">
								<!-- <img src="../../static/images/lstx/icon_bx.png" alt="" /> -->
								<p class="liutixi_box_item_title" style="height:56px;color:#831E21;line-height: 56px;">
									...N</p>
							</div>

							<div class="liutixi_box_item_textBox">
								<div class="liutixi_box_item_textBox_box">
									<div class="liutixi_box_item_textBox_title">
										...N
									</div>
									<div class="liutixi_box_item_textBox_text">
										...N
									</div>
									<img src="../../static/images/lstx/icon_xjt.png" alt=""
										class="liutixi_box_item_textBox_img" />
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>




		</div>


		<div class="dateBox">
			<div class="dateBox-item">
				<div class="dateBox-item-title">覆盖区域（区/县）</div>
				<div class="dateBox-item-text">
					<animate-number ref="reNum1" from="0" :to="index_data.data.total.company_county_count"
						:key="index_data.data.total.company_county_count" duration="5000"></animate-number>
				</div>
			</div>
			<div class="dateBox-item">
				<div class="dateBox-item-title">注册数字身份证企业</div>
				<div class="dateBox-item-text">
					<animate-number ref="reNum2" from="0" :to="index_data.data.total.company_count"
						:key="index_data.data.total.company_count" duration="5000"></animate-number>
				</div>
				<!-- {{}} -->
			</div>
			<div class="dateBox-item">
				<div class="dateBox-item-title">产品数字身份证</div>
				<div class="dateBox-item-text">
					<animate-number ref="reNum3" from="0" :to="index_data.data.total.product_count"
						:key="index_data.data.total.product_count" duration="5000"></animate-number>
				</div>
			</div>
			<div class="dateBox-item">
				<div class="dateBox-item-title">生态伙伴注册数</div>
				<div class="dateBox-item-text">
					<animate-number ref="reNum4" from="0" :to="index_data.data.total.standard_count"
						:key="index_data.data.total.standard_count" duration="5000"></animate-number>
				</div>
			</div>
		</div>

		<div class="companyList">
			<div class="companyListBox">
				<div class="companyTitle">
					<div>
						<img src="../../static/images/icon-business-security.svg" alt="" class="companyTitle-logo">
						中国一乡一品数字身份证企业
					</div>
					<div class="company_rz">
						<router-link to="/myCompany" style="color: #FF7164;">去注册我的企业 ></router-link>
					</div>
				</div>

				<div class="company_list">

					<div class="company_item" v-for="item in index_data.data.index_company"
						@click="goCompanyDetail(item.id)">
						<div class="company_item_img" v-if="item.logo">
							<img :src="item.logo" alt="">
						</div>
						<div class="company_item_textlogo" :style="randomRgb()" v-else>
							{{item.sTitle}}
						</div>
						<div class="comany_item_info">
							<p class="comany_item_info_title">{{item.company}}</p>
							<div class="comany_item_info_box">
								<p class="comany_item_info_text">所属行业：生产制造</p>
								<p class="comany_item_info_text">企业规模：{{item.companySizeText}}</p>
								<p class="comany_item_info_text">年营业额：{{item.companySaleText}}</p>
								<!-- 年营业额 改 所在地区 -->
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>



		<div class="companyList">
			<div class="companyListBox">
				<div class="companyTitle">
					<div>
						<img src="../../static/images/wendang.svg" alt="" class="companyTitle-logo">
						中国一乡一品数字身份证产品
					</div>
					<div class="company_rz">
						<router-link to="/myProduct" style="color: #FF7164;" class="show_pc">去申请一乡一品数字身份证
							></router-link>
						<router-link to="/myProduct" style="color: #FF7164;" class="show_m">去申请 ></router-link>
					</div>
				</div>

				<div class="product_list_home">

					<div class="product_item" v-for="item in index_data.data.index_product"
						@click="goProductDetail(item.id)">
						<div class="product_img_home" ref="pageContainer" :style="boxHeight">
							<img :src="item.infoimgTheList[0]"
								onerror="this.src='http://cdmfimg.otopchina.com/20220727_de5962c15bbc8a24b93e3b70c6e33fe8'">
						</div>
						<div class="product_info">
							<p class="product_info_title">{{item.name}}</p>
							<div class="product_price_star">
								<p class="product_price">￥{{item.price_sell}}</p>
								<img src="../../static/images/star2.png" class="product_star">
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>



		<div class="companyList">
			<div class="companyListBox">
				<div class="companyTitle">
					<div>
						<img src="../../static/images/fws_icon.png" alt="" class="companyTitle-logo">
						中国一乡一品认证生态伙伴
					</div>
					<div class="company_rz">
						<router-link to="/authentication" style="color: #FF7164;" class="show_pc">去注册成为中国一乡一品认证生态伙伴
							></router-link>
						<router-link to="/authentication" style="color: #FF7164;" class="show_m">去注册 ></router-link>
					</div>
				</div>

				<div class="fws_dq">
					<div class="fws_dq_item " :class="{'fws_dq_item_active':serviceCenterType ==''}"
						@click="serviceCenterTypeFn('')">
						全部
					</div>
					<!-- <div class="fws_dq_item" :class="{'fws_dq_item_active':serviceCenterType =='beijing'}"
						@click="serviceCenterTypeFn('beijing')">
						中心总部({{Servicemsg.beijingC}})
					</div> -->
					<div class="fws_dq_item" :class="{'fws_dq_item_active':serviceCenterType =='center'}"
						@click="serviceCenterTypeFn('center')">
						中部中心({{Servicemsg.centerC}})
					</div>
					<div class="fws_dq_item" :class="{'fws_dq_item_active':serviceCenterType =='west'}"
						@click="serviceCenterTypeFn('west')">
						西部中心({{Servicemsg.westC}})
					</div>
					<div class="fws_dq_item" :class="{'fws_dq_item_active':serviceCenterType =='north'}"
						@click="serviceCenterTypeFn('north')">
						北部中心({{Servicemsg.northC}})
					</div>

					<div class="fws_dq_item" :class="{'fws_dq_item_active':serviceCenterType =='east'}"
						@click="serviceCenterTypeFn('east')">
						东部中心({{Servicemsg.eastC}})
					</div>
					<div class="fws_dq_item" :class="{'fws_dq_item_active':serviceCenterType =='south'}"
						@click="serviceCenterTypeFn('south')">
						南部中心({{Servicemsg.southC }})
					</div>
				</div>
				<div class="fws_line"></div>
				<div class="fws_dq_xq">
					<div class="fws_dq_xq_item " :class="{'fws_dq_xq_item_active':serviceAreaCode ==item.name}"
						v-for="(item,index) in cityList" :key="item.name" @click="cityFn(item.name)">
						{{item.name}}
					</div>

				</div>

				<div class="fws_list">
					<div class="fws_item" v-for="item in ServiceList" :key="item.id" @click="goServiceDetail(item)">
						<img :src=item.logo alt="" class="fws_item_img">
						<div class="fws_item_box">

							<div class="fws_item_info">
								<div class="fws_item_name">{{item.company}}</div>
								<div style="display: flex;">

									<img v-if="item.starVal<5" class="fws_item_imgstar"
										src="../../static/images/starhui.png" alt="">
									<img v-else class="fws_item_imgstar" src="../../static/images/star.png" alt="">

									<div class="fws_item_star" style="margin-top: 2px;">{{item.starVal}}</div>
								</div>
							</div>
							<div class="fws_item_tips" style="margin-top: 10px;">
								<div v-for="val in item.mainBusiness" :key="val">{{val.value|txtFn}}</div>
							</div>
							<div class="fws_item_data" style="padding-bottom: 8px;">
								<div class="fws_item_data_item" style="line-height: 30px;">
									<span>服务企业</span>{{item.serviceCompanyNum}}家
								</div>
								<div class="fws_item_data_item">
									<span>业务流水</span>{{item.companySaleText}}
								</div>
							</div>

						</div>
					</div>
				</div>

				<router-link to="/serviceProviders"
					style="text-align: center;color: #FF5234;font-size: 14px;display: block;margin-top: 0px;">查看全部生态伙伴
					></router-link>
			</div>
		</div>



		<div class="hezuo">
			<p class="hezuo_ittle">合作伙伴</p>
			<div class="hezuoList">
				<a href="https://www.caiq.org.cn/" target="_blank" class="hezuoItem">
					<img src="../../static/images/hezuo1.png" class="img_fluid">
				</a>
				<a href="http://www.cnicg.cn/" target="_blank" class="hezuoItem">
					<img src="../../static/images/hezuo2.png" class="img_fluid">
				</a>
				<a href="https://www.aliyun.com/" target="_blank" class="hezuoItem">
					<img src="../../static/images/hezuo3.png" class="img_fluid">
				</a>
				<a href="https://www.tencent.com/zh-cn/" target="_blank" class="hezuoItem">
					<img src="../../static/images/hezuo4.png" class="img_fluid">
				</a>
				<a href="http://www.chinapost.com.cn/" target="_blank" class="hezuoItem">
					<img src="../../static/images/hezuo5.png" class="img_fluid">
				</a>
				<a href="http://www.cpic.com.cn/" target="_blank" class="hezuoItem">
					<img src="../../static/images/hezuo6.png" class="img_fluid">
				</a>
				<a href="https://www.95590.cn/" target="_blank" class="hezuoItem">
					<img src="../../static/images/hezuo7.png" class="img_fluid">
				</a>
				<a href="https://www.sinomach.com.cn/" target="_blank" class="hezuoItem">
					<img src="../../static/images/hezuo8.png" class="img_fluid">
				</a>
				<a href="https://www.sf-express.com/we/ow/chn/sc/index" target="_blank" class="hezuoItem">
					<img src="../../static/images/hezuo9.png" class="img_fluid">
				</a>
				<a href="https://fresh.jd.com/" target="_blank" class="hezuoItem">
					<img src="https://cdmfimg.otopchina.com/20230505_0e1f3bf2a7b5d4b6b519efa087328751"
						class="img_fluid">
				</a>
			</div>
		</div>

		<div class="hidden_box" style="height: 0px;">
			<span>
				hidden
			</span>
		</div>

		<div class="hidden_box" style="height: 0px;">
			<span>
				hidden
			</span>
		</div>

		<div class="hidden_box" style="height: 0px;">
			<span>
				hidden
			</span>
		</div>

		<div class="el-backtop" style="right: 40px; bottom: 40px;" v-show="showBackTop" @click="backTop"><i
				class="el-icon-caret-top"></i></div>

		<!-- foot -->
		<base-Foot></base-Foot>
		<!-- foot -->
	</div>
</template>

<script>
	// @ is an alias to /src
	// import HelloWorld from '@/components/HelloWorld.vue'
	import baseHead from '@/components/baseHead.vue'
	import baseFoot from '@/components/baseFoot.vue'
	import axios from "@/utils/request"

	export default {
		name: 'HomeView',
		components: {
			'base-Head': baseHead,
			'base-Foot': baseFoot
		},

		metaInfo: {
			title: '中国一乡一品公共服务平台 | 中国一乡一品产业促进计划 | 中国一乡一品数字身份证',
			meta: [{
					// set meta
					name: "description",
					content: "中国民贸一乡一品产业促进计划（简称OTOP）是由原国家质量监督检验检疫总局批准，中国民族贸易促进会（以下简称民贸会）牵头启动的，一项通过整合社会力量、建立科学的产业提升体系、面向全国2800多个县市、4万多个乡镇，梳理打造优势地域品牌，促进产业中心形成的综合性产业提升计划。通过6大体系和3大中心所构成的服务架构（简称6+3模式），对地区产业进行深度帮扶和提升，打造一批有影响力的知名特色品牌，提升产品的质量水平，进而促进地区产业提升，推动经济发展，民生改善。中国一乡一品产业促进计划通过多种形式、渠道助力县域特色产业发展，深度融合一二三产业，推动兴边富民、经济社会发展，成为国家乡村振兴战略的重要支点。通过建设中国一乡一品数字身份证平台，实现从区域品牌产品的筛查、甄选、推荐，实现区域特色产品从源头到餐桌的全流程管控。充分利用大数据和云平台管理，在已有软硬件设备和信息化的基础上打通各个环节的数据联网，围绕智慧种植、交易流程、全程溯源、动态监管，全链条全要素辅助区域特色产业实现区域特色产品的智慧管理。",
				},
				{
					name: "keywords",
					content: "中国一乡一品,中国一乡一品公共服务平台,一乡一品智慧平台,中国一乡一品产业促进计划,中国一乡一品数字身份证,中国一乡一品数字身份证企业",
				},
				{
					property: "release_date",
					content: "",
				},
			],
		},
		filters: {
			txtFn(val) {
				val = val.length > 5 ? val.slice(0, 5) + '...' : val
				return val
			},
			stextFn(val) {
				val = val.length > 35 ? val.slice(0, 46) + '...' : val
				return val
			}
		},


		data() {
			return {
				serviceAreaCode: "全部",
				serviceCenterType: "",
				cityList: [{
					name: '全部'
				}, {
					name: '北京'
				}, {
					name: '湖南'
				}, {
					name: '重庆'
				}, {
					name: '天津'
				}, {
					name: '浙江'
				}, {
					name: '广东'
				}, ],
				Servicemsg: "",
				ServiceList: [],
				islogin: false,
				userInfo: {},
				keyword: '',
				newList: [],
				noticeList: [],
				activeIndex: '1',
				searchType: '1',
				activeName: '1',
				index_data: {
					data: {
						index_news_banner: {
							title: ''
						},
						total: {
							company_county_count: 0,
							company_count: 0,
							product_count: 0,
							standard_count: 0,
						}
					}
				},

				initSearch: {},
				restaurants: [],
				baseCompanyImg: 'http://cdmfimg.otopchina.com/20220715_2fc102163520cfca9ed0813967668869',
				noImg: "http://cdmfimg.otopchina.com/20220727_de5962c15bbc8a24b93e3b70c6e33fe8",
				randomRgb(item) {
					let R = Math.floor(Math.random() * 130 + 110);
					let G = Math.floor(Math.random() * 130 + 110);
					let B = Math.floor(Math.random() * 130 + 110);
					return {
						background: 'rgb(' + R + ',' + G + ',' + B + ', 1)'
					};
				},
				boxHeight: '',
				form: {
					description: ''
				},
				tixiList: [],
				serviceTypeList: {
					list1: [],
					list2: [],
					list3: [],
				},
				showBackTop: false,
			};
		},
		methods: {
			parentFn(payload) {
				//负组件获取子组件的值
				console.log(payload);
				this.form.description = payload
			},
			initPage() {
				var vm = this
				var data = {
					appData: {
						clientType: 1
					},
					version: 2.1,
					sign: "3b0b8438b5c685e7e396f9e7c0394a41",
					time: 1642472216346,
					token: localStorage.getItem('token')
				}
				// axios.defaults.baseURL = 'https://cardapi.otopchina.com/groupprogram/v1/';

				axios.post("Index/getReachHomeList", data).then(res => {
					if (res.data.code == 1) {
						this.index_data = res.data
						if (this.index_data.data.index_company) {
							for (var i = 0; i < this.index_data.data.index_company.length; i++) {
								this.index_data.data.index_company[i].sTitle = this.index_data.data.index_company[
									i].company.substr(0, 4)
							}
						}
						for (var i = 0; i < this.index_data.data.index_product.length; i++) {
							if (this.index_data.data.index_product[i].infoimg) {
								this.index_data.data.index_product[i].infoimgTheList = this.index_data.data
									.index_product[i].infoimg.split(',')
							}
						}

					} else {
						console.log("error")
						vm.$message({
							type: 'error',
							message: res.data.msg,
							duration: 3000,
							showClose: true
						});
					}
				})
			},
			getHomeServiceList() {
				var vm = this
				var data = {
					appData: {
						page: 1,
						size: 8,
						serviceAreaCode: this.serviceAreaCode == '全部' ? '' : this.serviceAreaCode,
						serviceCenterType: this.serviceCenterType
					},
					version: 2.1,
					sign: "3b0b8438b5c685e7e396f9e7c0394a41",
					time: 1642472216346,
					token: localStorage.getItem('token')
				}
				// axios.defaults.baseURL = 'https://cardapi.otopchina.com/groupprogram/v1/';

				axios.post("Index/getHomeServiceList", data).then(res => {
					if (res.data.code == 1) {
						vm.ServiceList = res.data.data.list
						vm.Servicemsg = res.data.data
						// console.log(res.data)
					} else {
						console.log("error")
						vm.$message({
							type: 'error',
							message: res.data.msg,
							duration: 3000,
							showClose: true
						});
					}
				})
			},
			getNewList(classType) {
				var vm = this
				var data = {
					appData: {
						page: 1,
						size: 7,
						classType: classType,
						clientType: 1
					},
					version: 2.1,
					sign: "3b0b8438b5c685e7e396f9e7c0394a41",
					time: 1642472216346,
					token: localStorage.getItem('token'),
				}
				axios.post("Index/getNewList", data).then(res => {
					if (res.data.code == 1) {
						vm.newList = res.data.data.list
						for (var i = 0; i < vm.newList.length; i++) {
							if (vm.newList[i].addTime) {
								vm.newList[i].addTime = vm.newList[i].addTime.substr(0, 10)
							}
						}
					} else {
						console.log("error")
						vm.$message({
							type: 'error',
							message: res.data.msg,
							duration: 3000,
							showClose: true
						});
					}
				})
			},
			searchShow(row) {
				this.searchType = row
			},
			handleSelect(key, keyPath) {
				// console.log(key, keyPath);
			},
			handleClick(tab, event) {
				// console.log(tab.name);
				this.getNewList(tab.name)
			},
			setHotSearch(row, type) {
				if (type == 2) {
					this.goCompanyDetail(row)
				} else if (type == 1) {
					this.goProductDetail(row)
				}
			},
			cityFn(name) {
				this.serviceAreaCode = name
				this.getHomeServiceList()
			},
			serviceCenterTypeFn(name) {
				this.serviceCenterType = name
				this.serviceAreaCode = '全部'
				switch (name) {
					case '':
						this.cityList = [{
							name: '全部'
						}, {
							name: '湖南'
						}, {
							name: '重庆'
						}, {
							name: '天津'
						}, {
							name: '浙江'
						}, {
							name: '广东'
						}, ]
						break;
					case 'beijing':
						this.cityList = [{
							name: '全部'
						}, {
							name: '北京'
						}, ]
						break;
					case 'north':
						this.cityList = [{
							name: '全部'
						}, {
							name: '辽宁'
						}, {
							name: '吉林'
						}, {
							name: '黑龙江'
						}, {
							name: '河北'
						}, {
							name: '北京'
						}, {
							name: '天津'
						}, {
							name: '内蒙古'
						}, ]
						break;
					case 'center':
						this.cityList = [{
							name: '全部'
						}, {
							name: '山西'
						}, {
							name: '河南'
						}, {
							name: '安徽'
						}, {
							name: '湖北'
						}, {
							name: '江西'
						}, {
							name: '湖南'
						}, ]
						break;
					case 'west':
						this.cityList = [{
							name: '全部'
						}, {
							name: '四川'
						}, {
							name: '西藏'
						}, {
							name: '陕西'
						}, {
							name: '甘肃'
						}, {
							name: '青海'
						}, {
							name: '新疆'
						}, {
							name: '宁夏'
						}, {
							name: '重庆'
						}, ]
						break;
					case 'east':
						this.cityList = [{
							name: '全部'
						}, {
							name: '山东'
						}, {
							name: '江苏'
						}, {
							name: '上海'
						}, {
							name: '浙江'
						}, {
							name: '福建'
						}, {
							name: '台湾'
						}, ]
						break;
					case 'south':
						this.cityList = [{
							name: '全部'
						}, {
							name: '海南'
						}, {
							name: '广东'
						}, {
							name: '广西'
						}, {
							name: '贵州'
						}, {
							name: '云南'
						}, {
							name: '香港'
						}, {
							name: '澳门'
						}, ]
						break;

				}

				this.getHomeServiceList()
			},
			searchInit(token, id) {
				var vm = this
				var data = {}
				if (token) {
					data = {
						appData: {
							clientType: 1,
							uid: id,
						},
						version: 2.1,
						sign: "3b0b8438b5c685e7e396f9e7c0394a41",
						time: 1642472216346,
						token: token,
					}
				} else {
					data = {
						appData: {
							clientType: 1
						},
						version: 2.1,
						sign: "3b0b8438b5c685e7e396f9e7c0394a41",
						time: 1642472216346,
					}
				}
				axios.post("Search/searchInit", data).then(res => {
					if (res.data.code == 1) {
						vm.initSearch = res.data.data

						vm.tixiList = res.data.data.service_list.slice(0, 8);
						// console.log(vm.tixiList)
					} else {
						console.log("error")
						vm.$message({
							type: 'error',
							message: res.data.msg,
							duration: 3000,
							showClose: true
						});
					}
				})
			},
			querySearch(queryString, cb) {
				if (this.restaurants != []) {
					var restaurants = this.restaurants;
					var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
					// 调用 callback 返回建议列表的数据
					cb(results);
				}
			},
			createFilter(queryString) {
				return (restaurant) => {
					return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
				};
			},
			goSearch() {
				this.$router.push({
					name: "search",
					params: {
						keyword: this.keyword,
						searchType: this.searchType
					}
				});
			},
			goServiceDetail(row) {
				console.log(row.id)
				this.$router.push({
					name: 'serviceprovider', //跳转的路由（路由的name）
					params: { //params
						id: row.id,
					},
					query: {
						id: row.id,
					} //query对象，跳转到新路由，然后刷新页面，能继续获取到query 对象
				})
			},
			goCompanyDetail(row) {
				this.$router.push({
					name: 'companyDetail', //跳转的路由（路由的name）
					params: { //params
						id: row,
					},
					query: {
						id: row,
					} //query对象，跳转到新路由，然后刷新页面，能继续获取到query 对象
				})
			},
			goProductDetail(row) {
				this.$router.push({
					name: 'productDetail', //跳转的路由（路由的name）
					params: { //params
						id: row,
					},
					query: {
						id: row,
					} //query对象，跳转到新路由，然后刷新页面，能继续获取到query 对象
				})
			},
			showIcon() {
				if (!!document.documentElement.scrollTop && document.documentElement.scrollTop > 500) {
					this.$nextTick(() => {
						this.$refs.reNum1.start()
						this.$refs.reNum2.start()
						this.$refs.reNum3.start()
						this.$refs.reNum4.start()
					})
					window.removeEventListener('scroll', this.showIcon);
				} else {}
			},
			getServiceType() {
				var vm = this
				var data = {
					appData: {},
					version: 2.1,
					sign: "3b0b8438b5c685e7e396f9e7c0394a41",
					time: 1642472216346,
					token: localStorage.getItem('token'),
				}
				axios.post("Index/getServiceType", data).then(res => {
					if (res.data.code == 1) {
						vm.serviceTypeList = res.data.data
					} else {
						console.log("error")
						vm.$message({
							type: 'error',
							message: res.data.msg,
							duration: 3000,
							showClose: true
						});
					}
				})
			},
			showIcon() {
				if (!!document.documentElement.scrollTop && document.documentElement.scrollTop > 500) {
					this.showBackTop = true
					// window.removeEventListener('scroll',this.showIcon);
				} else {
					this.showBackTop = false
				}
			},
			backTop() {
				document.documentElement.scrollTop = 0;
			},
		},
		created() {

			this.showIcon()
			this.initPage()
			this.getNewList(1)
			this.getHomeServiceList()
			this.getServiceType()
		},
		mounted() {
			this.$nextTick(() => {
				setTimeout(() => {
					this.boxHeight = 'height:' + Array.prototype.slice.call(this.$refs.pageContainer)[0]
						.offsetWidth + "px"
				}, 1000)
			})
			var token = localStorage.getItem('token')
			if (token) {
				this.localStorageUserInfo = JSON.parse(localStorage.getItem('user_token'))
				this.searchInit(token, JSON.parse(localStorage.getItem('user_token')).id)
				this.restaurants = this.initSearch.user_search_list
			} else {
				this.searchInit()
			}
			window.addEventListener("scroll", this.showIcon);
		}
	}
</script>


<style>
	body {
		background: #F7F8FA;
	}

	html {
		/* min-width: 1237px; */
	}

	.search_box .el-button {
		color: #fff !important;
	}

	.el-menu--horizontal>.el-submenu .el-submenu__title {
		/* height: unset; */
		color: #FFFFFF;
		line-height: unset;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.el-menu--popup {
		min-width: 170px;
	}

	.el-input-group__append,
	.el-input-group__prepend {
		border: none !important;
	}

	.el-menu--horizontal .el-menu .el-menu-item {
		background-color: #FFF;
		float: none;
		height: 42px;
		line-height: 36px;
		padding: 0 10px;
		color: #909399;
	}

	.head-menu>.el-menu>.is-active {
		border-bottom: 0px !important;
		background: #fff !important;
		color: #FF4926 !important;
	}


	@media screen and (min-width:1024px) and (max-width:1550px) {
		.index_banner_search {
			width: 1050px;
			position: absolute;
			/* top: 50%; */
			top: 52% !important;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.index_banner_search_type {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin-top: 45px !important;
		}

		.hot_search {
			padding: 10px 0 !important;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}

		.index_banner_search_title {
			color: #FFFFFF;
			font-size: 26px !important;
			text-align: center;
		}

		.search_box {
			margin-top: 10px !important;
			background: #FFFFFF;
			box-shadow: -4px 8px 27px rgb(83 0 4 / 30%);
			border-radius: 8px;
		}
	}

	.index_banner_search {
		width: 1050px;
		position: absolute;
		/* top: 50%; */
		top: 52%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.index_banner_search_title {
		color: #FFFFFF;
		font-size: 30px;
		text-align: center;
	}

	.index_banner_search_type {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-top: 65px;
		margin-bottom: 30px;
	}

	.index_banner_search_type_item {
		position: relative;
		color: #FFFFFF;
		font-size: 18px;
		cursor: pointer;
		padding: 5px 35px;
		margin: 0px 50px;
	}

	/* 	.index_banner_search_type_item:nth-child(1) {
		margin-right: -250px;
	}
	.index_banner_search_type_item:nth-child(2) {
		margin-left: -250px;
	} */
	.search_type_img {
		width: 20px;
		height: auto;
		position: absolute;
		top: 120%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9;
	}

	.search_type_img {
		display: none;
		margin-top: 5px;
	}

	.search_type_item_action {
		border-radius: 8px;
		background: #FFFFFF;
		color: #FF4926;
	}

	/* .search_type_item_action>.search_type_img {
		display: block;
	} */

	.search_box {
		margin-top: 20px;
		background: #FFFFFF;
		box-shadow: -4px 8px 27px rgba(83, 0, 4, 0.3);
		border-radius: 8px;
	}

	.el-input-group__append {
		background: linear-gradient(112.11deg, #FF7164 0%, #FF4926 106.67%);
		border-radius: 0px 8px 8px 0px;
		text-align: center;
		font-size: 18px;
		color: #FFFFFF;
	}

	.el-input__inner {
		outline: none !important;
	}

	.search_box .el-autocomplete .el-input .el-input__inner {
		height: 48px;
		line-height: 48px;
		font-size: 16px;
		padding: 5px 15px;
	}



	.hot_search {
		padding: 25px 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.hot_search_logo {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		font-size: 12px;
		color: #fff;
		border-right: 1px solid #fff;
		width: 80px;
		margin-right: 10px;
		padding-right: 5px;
	}

	.hot_search_list {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;
		overflow: hidden;
	}

	.hot_search_item {
		font-size: 14px;
		text-align: left;
		margin: 2px 15px;
		color: #fff;
		cursor: pointer;
	}

	.search-input-box {
		width: 100%;
	}

	.newDetail-textBox P {
		/* text-indent: 2em; */
		/* line-height: 2em; */
	}

	.hidden_box {
		visibility: hidden;

	}




	@media screen and (min-width: 769px) {
		.show_m {
			display: none !important;
		}

		.index_banner {
			position: relative;
			min-width: 1237px;
		}


		.navlist {
			max-width: 1237px;
			min-width: 1237px;
			margin: 40px auto 0px auto;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}

		.navitem {
			width: 17%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 15px 0px 15px 25px;
			border-radius: 8px;
			align-items: center;
			cursor: pointer;
			/* translate: all 0.7s; */
			transition: all 0.5s;
		}

		.navitem:nth-child(1) {
			background: linear-gradient(241.64deg, #EEF1FF -3.9%, #CFD7FF -3.89%, #FFFFFF 60.39%);
		}

		.navitem:nth-child(2) {
			background: linear-gradient(211.89deg, rgba(252, 170, 255, 0.248) 8.89%, rgba(255, 255, 255, 0.4) 85.7%);
		}

		.navitem:nth-child(3) {
			background: linear-gradient(225.86deg, #FBC7A9 -70.26%, #FFFFFF 79.07%);
		}

		.navitem:nth-child(4) {
			background: linear-gradient(221.7deg, #AFE7FF -27.6%, #FFFFFF 78.2%);
		}

		.navitem:nth-child(5) {
			background: linear-gradient(215.31deg, #70EEB9 -31.08%, #9DFDD5 -31.07%, #FFFFFF 77.26%);
		}

		.navitem:hover {
			transform: scale(1.05);
		}

		.navitem-img {
			width: auto;
			height: 60px;
		}

		.navitem-left-title {
			color: #171717;
			font-weight: 400;
			font-size: 16px;
			text-align: left;
			font-weight: 600;
		}

		.navitem-left-text {
			font-size: 12px;
			color: #979797;
			margin-top: 10px;
			text-align: left;
		}

		.newList {
			max-width: 1237px;
			min-width: 1237px;
			margin: 40px auto 0px auto;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		.newList-left {
			width: 46%;
			border-radius: 8px;
			overflow: hidden;
			background: #fff;
		}

		.newList-rigth {
			width: 46%;
			padding: 15px 30px;
			border-radius: 8px;
			overflow: hidden;
			background: #fff;
		}

		.newList-left-img {
			width: 100%;
			max-height: 300px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			overflow: hidden;
		}

		.newList-left-info {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			padding: 15px;
			background: #fff;
		}

		.newList-left-title {
			color: #171717;
			font-size: 16px;
			width: 80%;
			text-align: left;
		}

		.newList-left-text {
			color: #9E9E9E;
			font-size: 12px;
		}

		.el-tabs__active-bar {
			width: 0px !important;
		}

		.el-tabs__nav-wrap::after {
			width: 0px !important;
		}

		.el-tabs__active-bar {
			height: 0px;
		}

		.newList-rigth>.el-tabs>.el-tabs__header>.el-tabs__nav-wrap>.el-tabs__nav-scroll>.el-tabs__nav>.el-tabs__item {
			text-align: center;
			padding: 0px 0px !important;
		}

		.newList-rigth>.el-tabs>.el-tabs__header>.el-tabs__nav-wrap>.el-tabs__nav-scroll>.el-tabs__nav>.el-tabs__item {
			color: #171717;
			/* font-size: 16px; */
			/* font-weight: 600; */
		}

		.newList-rigth>.el-tabs>.el-tabs__header>.el-tabs__nav-wrap>.el-tabs__nav-scroll>.el-tabs__nav>.is-active {
			background: linear-gradient(98.42deg, #FF6B5C -5.9%, #FF5234 105.44%) !important;
			border-radius: 8px !important;
			color: #FFFFFF !important;
			font-size: 16px !important;
		}

		.newItem {
			padding: 6px 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.101961);
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 5px;
		}

		.newItem-title {
			color: #171717;
			font-size: 16px;
			width: 85%;
			text-align: left;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.newItem-text {
			color: #9E9E9E;
			font-size: 12px;
		}

		.newMore {
			text-align: right;
			color: #FF7164;
			font-size: 12px;
			margin-top: 20px;
		}

		.newMore a {
			text-align: right;
			color: #FF7164;
			font-size: 12px;
			margin-top: 20px;
		}

		.tixi {
			max-width: 1157px;
			min-width: 1157px;
			margin: 40px auto 0px auto;
			padding: 20px 40px;
			background: #FFFFFF;
			border-radius: 8px;
		}

		.tixi_title {
			font-weight: 600;
			font-size: 20px;
			color: #000000;
			line-height: 20px;
		}

		.tixi_text {
			font-weight: 400;
			font-size: 14px;
			line-height: 14px;
			color: #999999;
			margin-top: 16px;
		}

		.tixi_list {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: wrap;
			margin-top: 40px;
		}

		.tixi_item {
			width: 237px;
			height: 110px;
			background: linear-gradient(180deg, #FFFFFF 0%, #FFF1F2 99%);
			border: 1px solid #EEEEEE;
			position: relative;
			padding: 30px 25px;
			background-image: url(../../static/images/logo_d.png);
			background-repeat: no-repeat;
			background-position: right bottom;
			background-size: inherit;
			cursor: pointer;
		}

		.tixi_item_title {
			font-weight: 600;
			font-size: 20px;
			color: #000000;
			line-height: 20px;
			text-align: left;
		}

		.tixi_hover_item {
			position: absolute;
			top: 0px;
			left: 0px;
			right: 0px;
			bottom: 0px;
			display: none;
			cursor: pointer;
			padding: 30px 25px;
			background: url(../../static/images/logo_e.png);
			/* border: 1px solid #EEEEEE; */
			background-repeat: no-repeat;
			background-size: cover;
		}

		.tixi_item:hover .tixi_hover_item {
			display: block !important;
		}

		.tixi_hover_item_active {
			display: block !important;
		}

		.tixi_hover_item_title {
			font-weight: 600;
			font-size: 20px;
			color: #FFFFFF;
			line-height: 20px;
			text-align: left;
		}

		.tixi_hover_item_text {
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
			color: #FFFFFF;
			text-align: left;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-top: 12px;
		}

		.tixi_hover_item_xq {
			font-weight: 400;
			font-size: 14px;
			text-align: left;
			color: #FFFFFF;
			line-height: 14px;
			text-align: left;
			margin-top: 20px;
		}

		.dateBox {
			max-width: 1237px;
			min-width: 1237px;
			margin: 40px auto 0 auto;
			padding: 30px 0px;
			background: url(../../static/images/datebg.png) no-repeat no-repeat;
			background-size: 100%;
			background-position: center center;
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			border-radius: 8px;
		}

		.dateBox-item-title {
			color: #171717;
			font-weight: 400;
			font-size: 18px;
			text-align: center;
		}

		.dateBox-item-text {
			color: #891C21;
			font-weight: 700;
			font-size: 32px;
			text-align: center;
			margin-top: 10px;
		}

		.companyList {
			max-width: 1237px;
			min-width: 1237px;
			margin: 40px auto 0px auto;
			padding: 10px 0px;
			background: #FFFFFF;
			border-radius: 8px;
		}

		.companyListBox {
			padding: 10px;
		}

		.companyTitle {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			font-weight: 600;
		}

		.companyTitle>div {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			font-weight: 600;
		}

		.companyTitle-logo {
			margin: 0 10px 0 0;
		}

		.company_rz {
			/* margin-top: 25px; */
			color: #FF7164;
			font-size: 14px;
			text-align: right;
			/* margin-right: 2.5%; */
			cursor: pointer;
		}

		.company_list {
			margin-top: 25px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.company_item {
			width: 32%;
			margin: 10px 0;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			background: #F8F8F8;
			border-radius: 8px;
			padding: 20px;
			box-sizing: border-box;
			border: 1px solid #F8F8F8;
			cursor: pointer;
			overflow: hidden;
		}

		.el-button a {
			color: #fff;
		}

		.company_item:hover {
			background: #fff;
			border: 1px solid #FF4926;
			box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.18);
			border-radius: 8px;
		}

		.company_item_img {
			width: 100px;
			height: 100px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			overflow: hidden;
		}

		.company_item_img img {
			width: 100%;
			height: auto;
		}

		.company_item_textlogo {
			width: 100px;
			height: 100px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			font-size: 36px;
			background-color: #00B7EE;
			overflow: hidden;
			color: #fff;
			border-radius: 8px;
		}

		.comany_item_info {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			margin-left: 20px;
			overflow: hidden;
		}

		.comany_item_info_title {
			color: #1D2129;
			font-size: 16px;
			font-weight: 600;
			text-align: left;
			overflow: hidden;
			white-space: nowrap;
			/*文字超出宽度则显示ellipsis省略号*/
			text-overflow: ellipsis;
		}

		.comany_item_info_box {
			margin-top: 10px;
		}

		.comany_item_info_text {
			color: #4E5969;
			font-size: 12px;
			text-align: left;
		}

		.fws_dq {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			border-bottom: 2px solid #F95538;
			margin-top: 30px;
		}

		.fws_dq_item {
			font-weight: 600;
			font-size: 14px;
			color: #666666;
			line-height: 20px;
			padding: 6px 25px;
			cursor: pointer;
		}

		.fws_dq_item_active {
			background: #F95538;
			border-radius: 8px 8px 0px 0px;
			color: #fff;
		}

		.fws_dq_xq {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			margin-top: 15px;
		}

		.fws_dq_xq_item {
			font-weight: 600;
			font-size: 14px;
			color: #666666;
			line-height: 20px;
			padding: 5px 20px;
			cursor: pointer;
			border-radius: 2px;
		}

		.fws_dq_xq_item_active {
			background-color: rgba(249, 85, 56, 0.2);
			color: #F95538;
		}

		.fws_list {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: wrap;
			margin-top: 20px;
		}

		.fws_item {
			width: 23%;
			margin: 0 1%;
			background: #F8F8F8;
			border-radius: 8px;
			margin-bottom: 20px;
			overflow: hidden;
			cursor: pointer;
		}

		/* .fws_item:hover {
			border: 1px solid #FF4926;
			box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.18);
			border-radius: 8px;
		} */

		.fws_item_img {
			width: 290px;
			height: 165px;
		}

		.fws_item_box {
			padding: 15px;
		}

		.fws_item_info {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}

		.fws_item_name {
			font-weight: 600;
			font-size: 20px;
			color: #000000;
			line-height: 20px;
			text-align: left;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			overflow: hidden;
		}

		.fws_item_star {
			font-weight: 600;
			font-size: 20px;
			color: #F95538;
			line-height: 20px;
			text-align: right;
		}

		.fws_item_imgstar {
			width: 20px;
			height: 20px;
			margin-left: 20px;
			margin: 5px;
			margin-top: 1px;
		}

		.fws_item_tips {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			margin-top: 5px;
			overflow: hidden;
			height: 19px;
			flex-wrap: wrap;
		}

		.fws_item_tips div {
			font-weight: 500;
			font-size: 13px;
			color: #F95538;
			line-height: 13px;
			padding: 3px;
			background: #FEDDD7;
			border-radius: 2px;
			margin-right: 15px;
		}

		.fws_item_data {
			margin-top: 20px;
		}

		.fws_item_data_item {
			font-weight: 600;
			font-size: 16px;
			color: #000000;
			line-height: 13px;
			text-align: left;
			margin-top: 5px;
		}

		.fws_item_data_item span {
			font-weight: 400;
			font-size: 13px;
			color: #666666;
			line-height: 13px;
			text-align: left;
			margin-right: 15px;
		}

		.product_list_home {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-top: 25px;
		}

		.product_item {
			width: 18%;
			border: 1px solid #fff;
			border-radius: 8px;
			cursor: pointer;
			border-radius: 8px;
			overflow: hidden;
			background: #f8f8f8;
			margin: 10px 0;
		}

		.product_item:hover {
			border: 1px solid #FF4926;
			box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.18);
			border-radius: 8px;
		}

		.product_img_home {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			overflow: hidden;
		}

		.product_img_home img {
			width: 100%;
			height: auto;
		}

		.product_info {
			padding: 10px;
			background: #F8F8F8;
		}

		.product_info_title {
			color: #1D2129;
			font-size: 16px;
			font-weight: 600;
			word-break: break-all;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			overflow: hidden;
			text-align: left;

		}

		.product_price_star {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;
		}

		.product_price {
			color: #FF4926;
			font-size: 14px;
		}

		.product_star {
			width: 24px;
			height: auto;
		}

		.hezuo {
			max-width: 1237px;
			min-width: 1237px;
			margin: 40px auto 40px auto;

		}

		.hezuo_ittle {
			color: #222222;
			font-size: 18px;
			text-align: center;
			font-weight: 600;
		}

		.hezuoList {
			margin-top: 25px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.hezuoItem {
			width: 19%;
			margin: 10px 0;
		}

		.lstx {
			max-width: 1157px;
			min-width: 1157px;
			margin: 40px auto 0px auto;
			background: #FFFFFF;
			padding: 50px 40px;
			border-radius: 8px;
		}

		.lstx img {
			vertical-align: bottom;
		}

		.lstx_title {
			font-size: 32px;
			color: #831E21;
			line-height: 40px;
			text-align: center;
			font-weight: 900;
		}

		.liutixi {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			margin-top: 40px;
		}

		.liutixi_img img {
			width: 67px;
			height: auto;
		}

		.liutixi_box {
			width: 1030px;
		}

		.liutixi_box_title img {
			width: 100%;
			height: auto;
			vertical-align: bottom;
		}

		.liutixi_box_list {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
		}

		.liutixi_box_item {
			width: 16.666667%;
			padding: 50px 0px;
			background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 67%, #F9F4F4 100%);
			cursor: pointer;
		}

		.liutixi_box_item:hover {
			padding: 0px 0px;
		}

		.liutixi_box_item:hover .liutixi_box_item_box {
			display: none;
		}

		.liutixi_box_item:hover .liutixi_box_item_textBox {
			display: block;
		}

		.liutixi_box_item_textBox {
			display: none;
			padding: 12px 20px;
			background: #831E21;
			height: 143px;
		}

		.liutixi_box_item_textBox_box {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
		}

		.liutixi_box_item_textBox_title {
			font-weight: 600;
			font-size: 15px;
			color: #FFFFFF;
			line-height: 20px;
			text-align: left;
		}

		.liutixi_box_item_textBox_text {
			font-weight: 400;
			font-size: 12px;
			color: #FFFFFF;
			line-height: 20px;
			text-align: justify;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			/* 设置为3行 */
			overflow: hidden;
			text-overflow: ellipsis;
			line-height: 1.5;
			/* 根据需要调整行高 */
			max-height: calc(1.5em * 3);
			/* 根据行高和行数计算最大高度 */
			margin-top: 12px;
		}

		.liutixi_box_item_textBox_img {
			width: 21px !important;
			height: auto;
			margin-top: 30px;
			text-align: left;
		}

		.liutixi_box_item_box {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-right: 3px solid #831E21;
		}

		.liutixi_box_item:nth-last-child(1) .liutixi_box_item_box {
			border-right: unset;
		}

		.liutixi_box_item:nth-child(6) .liutixi_box_item_box {
			border-right: unset;
		}

		.liutixi_box_item img {
			width: 37px;
			height: auto;
		}

		.liutixi_box_item_title {
			font-weight: 600;
			font-size: 15px;
			color: #000000;
			line-height: 20px;
			margin-top: 10px;
		}

		.ntixi_box_content {
			background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 67%, #F9F4F4 100%);
			padding: 20px;
			font-weight: 400;
			font-size: 14px;
			color: #000000;
			line-height: 20px;
			text-align: left;
		}
	}

	@media screen and (max-width: 768px) {
		.show_pc {
			display: none !important;
		}

		.index_banner {
			width: 100% !important;
			min-width: unset !important;
		}

		.index_banner_search {
			width: 80%;
			position: absolute;
			/* top: 50%; */
			top: 45% !important;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.index_banner_search_type {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin-top: 45px !important;
		}

		.index_banner_search_title {
			color: #FFFFFF;
			font-size: 26px !important;
			text-align: center;
		}

		.index_banner_search_title {
			color: #FFFFFF;
			font-size: 30px;
			text-align: center;
		}

		.index_banner_search_type {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin-top: 65px;
			margin-bottom: 30px;
		}

		.index_banner_search_type_item {
			position: relative;
			color: #FFFFFF;
			font-size: 18px;
			cursor: pointer;
			padding: 5px 10px;
			margin: 0px 10px;
		}

		.search_type_item_action {
			border-radius: 8px;
			background: #FFFFFF;
			color: #FF4926;
		}


		.hot_search_list {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			flex-wrap: wrap;
			overflow: hidden;
		}


		.hot_search_item {
			font-size: 14px;
			text-align: center;
			margin: 2px 15px;
			color: #fff;
			cursor: pointer;
		}

		.navlist {
			margin: 20px auto 0px auto;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			padding: 0px 10px;
		}

		.navitem {
			width: 45%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 15px 5px;
			border-radius: 8px;
			align-items: center;
			cursor: pointer;
			/* translate: all 0.7s; */
			transition: all 0.5s;
			margin: 5px 1%;
		}

		.navitem:nth-child(1) {
			background: linear-gradient(241.64deg, #EEF1FF -3.9%, #CFD7FF -3.89%, #FFFFFF 60.39%);
		}

		.navitem:nth-child(2) {
			background: linear-gradient(211.89deg, rgba(252, 170, 255, 0.248) 8.89%, rgba(255, 255, 255, 0.4) 85.7%);
		}

		.navitem:nth-child(3) {
			background: linear-gradient(225.86deg, #FBC7A9 -70.26%, #FFFFFF 79.07%);
		}

		.navitem:nth-child(4) {
			background: linear-gradient(221.7deg, #AFE7FF -27.6%, #FFFFFF 78.2%);
		}

		.navitem:nth-child(5) {
			background: linear-gradient(215.31deg, #70EEB9 -31.08%, #9DFDD5 -31.07%, #FFFFFF 77.26%);
		}

		.navitem:hover {
			transform: scale(1.05);
		}

		.navitem-img {
			width: auto;
			height: 50px;
		}

		.navitem-left-title {
			color: #171717;
			font-weight: 400;
			font-size: 16px;
			text-align: left;
			font-weight: 600;
		}

		.navitem-left-text {
			font-size: 12px;
			color: #979797;
			margin-top: 10px;
			text-align: left;
		}

		.newList {
			margin: 20px auto 0px auto;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.newList-left {
			/* width: 46%; */
			border-radius: 8px;
			overflow: hidden;
			background: #fff;
		}

		.newList-rigth {
			/* width: 46%; */
			padding: 15px 30px;
			border-radius: 8px;
			overflow: hidden;
			background: #fff;
			margin-top: 20px;
		}

		.newList-left-img {
			width: 100%;
			max-height: 300px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			overflow: hidden;
		}

		.newList-left-info {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			padding: 15px;
			background: #fff;
		}

		.newList-left-title {
			color: #171717;
			font-size: 16px;
			width: 57%;
			text-align: left;
			white-space: nowrap;
			/* 防止文本换行 */
			overflow: hidden;
			/* 隐藏溢出的文本 */
			text-overflow: ellipsis;
			/* 显示省略号 */
		}

		.newList-left-text {
			color: #9E9E9E;
			font-size: 12px;
		}

		.el-tabs__active-bar {
			width: 0px !important;
		}

		.el-tabs__nav-wrap::after {
			width: 0px !important;
		}

		.el-tabs__active-bar {
			height: 0px;
		}

		.newList-rigth>.el-tabs>.el-tabs__header>.el-tabs__nav-wrap>.el-tabs__nav-scroll>.el-tabs__nav>.el-tabs__item {
			text-align: center;
			padding: 0px 0px !important;
		}

		.newList-rigth>.el-tabs>.el-tabs__header>.el-tabs__nav-wrap>.el-tabs__nav-scroll>.el-tabs__nav>.el-tabs__item {
			color: #171717;
			/* font-size: 16px; */
			/* font-weight: 600; */
		}

		.newList-rigth>.el-tabs>.el-tabs__header>.el-tabs__nav-wrap>.el-tabs__nav-scroll>.el-tabs__nav>.is-active {
			background: linear-gradient(98.42deg, #FF6B5C -5.9%, #FF5234 105.44%) !important;
			border-radius: 8px !important;
			color: #FFFFFF !important;
			font-size: 16px !important;
		}

		.newItem {
			padding: 6px 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.101961);
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 5px;
		}

		.newItem-title {
			color: #171717;
			font-size: 16px;
			width: 80%;
			text-align: left;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.newItem-text {
			color: #9E9E9E;
			font-size: 12px;
		}

		.newMore {
			text-align: right;
			color: #FF7164;
			font-size: 12px;
			margin-top: 20px;
		}

		.newMore a {
			text-align: right;
			color: #FF7164;
			font-size: 12px;
			margin-top: 20px;
		}

		.tixi {
			margin: 20px auto 0px auto;
			padding: 20px 10px;
			background: #FFFFFF;
			border-radius: 8px;
		}

		.tixi_title {
			font-weight: 600;
			font-size: 20px;
			color: #000000;
			line-height: 20px;
		}

		.tixi_text {
			font-weight: 400;
			font-size: 14px;
			line-height: 14px;
			color: #999999;
			margin-top: 16px;
		}

		.tixi_list {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: wrap;
			margin-top: 30px;
		}

		.tixi_item {
			width: 43%;
			/* height: 110px; */
			background: linear-gradient(180deg, #FFFFFF 0%, #FFF1F2 99%);
			border: 1px solid #EEEEEE;
			position: relative;
			padding: 30px 3%;
			background-image: url(../../static/images/logo_d.png);
			background-repeat: no-repeat;
			background-position: right bottom;
			background-size: inherit;
			cursor: pointer;
		}

		.tixi_item_title {
			font-weight: 600;
			font-size: 16px;
			color: #000000;
			line-height: 16px;
			text-align: left;
		}

		.tixi_hover_item {
			position: absolute;
			top: 0px;
			left: 0px;
			right: 0px;
			bottom: 0px;
			display: none;
			cursor: pointer;
			padding: 30px 25px;
			background: url(../../static/images/logo_e.png);
			/* border: 1px solid #EEEEEE; */
			background-repeat: no-repeat;
			background-size: cover;
		}

		.tixi_item:hover .tixi_hover_item {
			display: block !important;
		}

		.tixi_hover_item_active {
			display: block !important;
		}

		.tixi_hover_item_title {
			font-weight: 600;
			font-size: 20px;
			color: #FFFFFF;
			line-height: 20px;
			text-align: left;
		}

		.tixi_hover_item_text {
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
			color: #FFFFFF;
			text-align: left;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-top: 12px;
		}

		.tixi_hover_item_xq {
			font-weight: 400;
			font-size: 14px;
			text-align: left;
			color: #FFFFFF;
			line-height: 14px;
			text-align: left;
			margin-top: 20px;
		}

		.dateBox {
			margin: 20px auto 0 auto;
			padding: 30px 0px;
			background: url(../../static/images/datebg.png) no-repeat no-repeat;
			background-size: auto;
			background-position: center center;
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			flex-wrap: wrap;
			border-radius: 8px;
		}

		.dateBox-item {
			width: 50%;
			margin: 10px 0;
		}

		.dateBox-item-title {
			color: #171717;
			font-weight: 400;
			font-size: 18px;
			text-align: center;
		}

		.dateBox-item-text {
			color: #891C21;
			font-weight: 700;
			font-size: 32px;
			text-align: center;
			margin-top: 10px;
		}

		.companyList {
			margin: 20px auto 0px auto;
			padding: 10px 0px;
			background: #FFFFFF;
			border-radius: 8px;
		}

		.companyListBox {
			padding: 10px;
		}

		.companyTitle {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			font-weight: 600;
		}

		.companyTitle>div {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			font-weight: 600;
		}

		.companyTitle-logo {
			margin: 0 10px 0 0;
		}

		.company_rz {
			/* margin-top: 25px; */
			color: #FF7164;
			font-size: 14px;
			text-align: right;
			/* margin-right: 2.5%; */
			cursor: pointer;
		}

		.company_list {
			margin-top: 25px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.company_item {
			width: 49%;
			margin: 10px 0;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			background: #F8F8F8;
			border-radius: 8px;
			padding: 10px;
			box-sizing: border-box;
			border: 1px solid #F8F8F8;
			cursor: pointer;
			overflow: hidden;
		}

		.el-button a {
			color: #fff;
		}

		.company_item:hover {
			background: #fff;
			border: 1px solid #FF4926;
			box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.18);
			border-radius: 8px;
		}

		.company_item_img {
			width: 100%;
			height: 100px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			overflow: hidden;
		}

		.company_item_img img {
			width: 100%;
			height: auto;
		}

		.company_item_textlogo {
			width: 100px;
			height: 100px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			font-size: 36px;
			background-color: #00B7EE;
			overflow: hidden;
			color: #fff;
			border-radius: 8px;
		}

		.comany_item_info {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			/* margin-left: 20px; */
			overflow: hidden;
		}

		.comany_item_info_title {
			color: #1D2129;
			font-size: 16px;
			font-weight: 600;
			text-align: left;
			overflow: hidden;
			white-space: nowrap;
			/*文字超出宽度则显示ellipsis省略号*/
			text-overflow: ellipsis;
		}

		.comany_item_info_box {
			margin-top: 10px;
		}

		.comany_item_info_text {
			color: #4E5969;
			font-size: 12px;
			text-align: left;
		}

		.fws_dq {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			border-bottom: 2px solid #F95538;
			margin-top: 30px;
		}

		.fws_dq_item {
			font-weight: 600;
			font-size: 14px;
			color: #666666;
			line-height: 20px;
			padding: 6px 10px;
			cursor: pointer;
		}

		.fws_dq_item_active {
			background: #F95538;
			border-radius: 8px 8px 0px 0px;
			color: #fff;
		}

		.fws_dq_xq {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			margin-top: 15px;
		}

		.fws_dq_xq_item {
			font-weight: 600;
			font-size: 14px;
			color: #666666;
			line-height: 20px;
			padding: 5px;
			cursor: pointer;
			border-radius: 2px;
		}

		.fws_dq_xq_item_active {
			background-color: rgba(249, 85, 56, 0.2);
			color: #F95538;
		}

		.fws_list {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: wrap;
			margin-top: 20px;
		}

		.fws_item {
			width: 23%;
			margin: 0 1%;
			background: #F8F8F8;
			border-radius: 8px;
			margin-bottom: 20px;
			overflow: hidden;
			cursor: pointer;
		}

		/* .fws_item:hover {
			border: 1px solid #FF4926;
			box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.18);
			border-radius: 8px;
		} */

		.fws_item_img {
			width: 290px;
			height: 165px;
		}

		.fws_item_box {
			padding: 15px;
		}

		.fws_item_info {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}

		.fws_item_name {
			font-weight: 600;
			font-size: 20px;
			color: #000000;
			line-height: 20px;
			text-align: left;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			overflow: hidden;
		}

		.fws_item_star {
			font-weight: 600;
			font-size: 20px;
			color: #F95538;
			line-height: 20px;
			text-align: right;
		}

		.fws_item_imgstar {
			width: 20px;
			height: 20px;
			margin-left: 20px;
			margin: 5px;
			margin-top: 1px;
		}

		.fws_item_tips {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			margin-top: 5px;
			overflow: hidden;
			height: 19px;
			flex-wrap: wrap;
		}

		.fws_item_tips div {
			font-weight: 500;
			font-size: 13px;
			color: #F95538;
			line-height: 13px;
			padding: 3px;
			background: #FEDDD7;
			border-radius: 2px;
			margin-right: 15px;
		}

		.fws_item_data {
			margin-top: 20px;
		}

		.fws_item_data_item {
			font-weight: 600;
			font-size: 16px;
			color: #000000;
			line-height: 13px;
			text-align: left;
			margin-top: 5px;
		}

		.fws_item_data_item span {
			font-weight: 400;
			font-size: 13px;
			color: #666666;
			line-height: 13px;
			text-align: left;
			margin-right: 15px;
		}

		.product_list_home {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-top: 25px;
		}

		.product_item {
			width: 49%;
			border: 1px solid #fff;
			border-radius: 8px;
			cursor: pointer;
			border-radius: 8px;
			overflow: hidden;
			background: #f8f8f8;
			margin: 10px 0;
		}

		.product_item:hover {
			border: 1px solid #FF4926;
			box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.18);
			border-radius: 8px;
		}

		.product_img_home {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			overflow: hidden;
		}

		.product_img_home img {
			width: 100%;
			height: auto;
		}

		.product_info {
			padding: 10px;
			background: #F8F8F8;
		}

		.product_info_title {
			color: #1D2129;
			font-size: 16px;
			font-weight: 600;
			word-break: break-all;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			overflow: hidden;
			text-align: left;

		}

		.product_price_star {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;
		}

		.product_price {
			color: #FF4926;
			font-size: 14px;
		}

		.product_star {
			width: 24px;
			height: auto;
		}

		.hezuo {
			margin: 20px auto 20px auto;
			padding: 0px 10px;
		}

		.hezuo_ittle {
			color: #222222;
			font-size: 18px;
			text-align: center;
			font-weight: 600;
		}

		.hezuoList {
			margin-top: 20px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.hezuoItem {
			width: 49%;
			margin: 10px 0;
		}

		.lstx {
			display: none;
		}

	}
</style>